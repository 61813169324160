import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {TextField, Typography, Button} from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import classNames from 'classnames';

import Utils from '../api/api';
import theme from '../layout/theme';

const styles = {
  hide: {
    display: 'none'
  },
  error: {
    color: theme.palette.error[500],
    textAlign: 'center'
  },
  success: {
    color: theme.palette.primary[500],
    textAlign: 'center'
  },
  container: {
    /* position: 'fixed', */
    zIndex: 10001,
    left: 0,
    right: 0,
    top: 56,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  [theme.breakpoints.up('sm')]: {
    container: {
      top: 64
    }
  },
  form: {
    width: 300,
    margin: '0 auto',
    padding: 30,
    border: 'solid 1px #eee',
    boxShadow: '0 0 15px rgba(0,0,0,0.1)',
    background: '#fff'
  },
  formRow: {
    justifyContent: 'center',
    marginTop: 25
  }
};

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      error: false
    };
  }
  componentDidMount() {
    var params = this.context.router.route.match.params;
    var $this = this;
    Utils._post('users/reset-pass/',{token:params.token},function(data){
      $this.setState({
        error: data.status ==='failed',
        message: data.message
      },function(){

      });
    });
  }
  handleBack(e) {
    //console.log(this.context);
    this.context.router.history.push('/login/');
  }
  render() {
    const classes = this.props.classes;
    return (
      <div className={classes.container}>
        <div className={classes.form} noValidate>
          <Typography type="body2" className={classNames(!this.state.error ? classes.success : classes.error)} >{this.state.message}</Typography>
          <FormGroup row className={classes.formRow}>
            <Button
              variant="contained"
              color="primary"
              onClick={(event) => this.handleBack(event)}>Tới Trang Đăng nhập</Button>
          </FormGroup>
        </div>
      </div>
    );
  }
}

ResetPassword.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(ResetPassword);
